import React from 'react'
import '../styles/CareersContact.css'

const CareersContact = ({ title, subtitle, start, contacts }) => {
    return (
        <section id="careers-contact" className="careers-contact-container">
            <div className="container">
                <div className="text-center">
                    <h2>{title}</h2>
                    <p>{subtitle}</p>
                </div>
                <div className="cards-container">
                    {contacts.map((contact, index) => (
                        <div key={index} className="card">
                            <img
                                src={contact.icon}
                                alt={contact.title}
                                className="icon"
                            />
                            <h4>{contact.title}</h4>
                            <p>{contact.description}</p>
                            <a
                                href={contact.cta}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-primary"
                            >
                                {start}
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default CareersContact
