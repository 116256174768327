import React from 'react'
import styles from '../../styles/AX-Gastro/AX-Gastro.css'

const LandingGrowth = () => {
    return (
        <section className={`py-5 ${styles.marketingSection}`}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 mb-5 mb-lg-0">
                        <div className="d-flex flex-column gap-4">
                            <div className="d-flex align-items-center">
                                <span className={styles.emoji}>📈</span>
                                <span className="text-success fw-semibold ms-2">
                                    Growth Strategies
                                </span>
                            </div>
                            <h2 className="display-5 fw-bold mb-3">
                                Monthly Marketing Strategies for Massive Growth
                            </h2>
                            <p className="lead mb-4">
                                Want to dominate your market? Our community
                                members are constantly ahead of the curve with
                                the latest marketing strategies tailored for
                                gastronomy. From social media hacks to the best
                                CRM tools, we bring the future of restaurant
                                marketing directly to you.
                            </p>
                            <div
                                className={`${styles.urgencyBanner} bg-success text-white p-3 rounded-3`}
                            >
                                <h3 className="h5 mb-2">
                                    🚀 Exclusive Updates
                                </h3>
                                <p className="mb-0">
                                    These exclusive updates are only available
                                    to active community members – don't be left
                                    behind.
                                </p>
                            </div>
                            <ul className="list-unstyled mb-4">
                                {[
                                    'Social Media Hacks',
                                    'Best CRM Tools',
                                    'Tailored Marketing Strategies',
                                ].map((item, index) => (
                                    <li
                                        key={index}
                                        className="d-flex align-items-center mb-2"
                                    >
                                        <svg
                                            className="text-success me-2"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className={styles.imageWrapper}>
                            <div
                                className={`${styles.marketingImage} rounded-5`}
                            >
                                <img
                                    src="./assets/img/portfolio-3.png"
                                    alt="Marketing strategies visualization"
                                    className="img-fluid rounded-5 "
                                />
                            </div>
                            <div
                                className={`${styles.marketingCard} bg-white rounded-4 p-3 shadow`}
                            >
                                <div className={`${styles.marketingIcon} mb-2`}>
                                    <svg
                                        width="40"
                                        height="40"
                                        viewBox="0 0 40 40"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle
                                            cx="20"
                                            cy="20"
                                            r="20"
                                            fill="#28a745"
                                        />
                                        <path
                                            d="M10 30L18 22L24 28L30 10"
                                            stroke="white"
                                            strokeWidth="4"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                                <div className="text-dark fw-bold fs-4">
                                    Growth Insights
                                </div>
                                <div className="text-muted">
                                    Updated Monthly
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LandingGrowth
