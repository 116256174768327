import React from 'react'
import { useTranslation } from 'react-i18next'

const PrivacyPolicy = () => {
    const { t } = useTranslation()

    return (
        <div className="privacy-policy">
            <div className="container">
                <h1>{t('privacyPolicy.title')}</h1>
                <p>{t('privacyPolicy.introduction')}</p>
                <section>
                    <h2>{t('privacyPolicy.responsible.title')}</h2>
                    <p>{t('privacyPolicy.responsible.description')}</p>
                </section>
                <section>
                    <h2>{t('privacyPolicy.dataCollection.title')}</h2>
                    <p>{t('privacyPolicy.dataCollection.description')}</p>
                </section>
                <section>
                    <h2>{t('privacyPolicy.dataUsage.title')}</h2>
                    <ul>
                        {t('privacyPolicy.dataUsage.list', {
                            returnObjects: true,
                        }).map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </section>
                <section>
                    <h2>{t('privacyPolicy.rights.title')}</h2>
                    <p>{t('privacyPolicy.rights.description')}</p>
                </section>
                <section>
                    <h2>{t('privacyPolicy.contact.title')}</h2>
                    <p>{t('privacyPolicy.contact.description')}</p>
                </section>
            </div>
        </div>
    )
}

export default PrivacyPolicy
