import React from 'react'
import styles from '../../styles/AX-Gastro/AX-Gastro.css'

const AXLandingCard = () => {
    return (
        <section className={`py-5 ${styles.proMembershipSection}`}>
            <div className="container">
                <div className="text-center mb-5">
                    <h2 className="display-5 fw-bold">
                        Apply for AX Gastro Pro – Only 12 Spots Available!
                    </h2>
                    <p className="lead">
                        Limited to 12 Restaurant Owners – Applications Open from
                        12 to 18
                    </p>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div
                            className={`${styles.proCard} bg-white p-4 rounded-4 shadow`}
                        >
                            <p className="mb-4">
                                Ready to take your restaurant to the next level?
                                AX Gastro Pro offers an exclusive membership for
                                only 12 restaurant entrepreneurs in Zurich and
                                surrounding areas. The subscription window is
                                open from the 12th to the 18th – and once these
                                spots are gone, they're gone for good!
                            </p>
                            <h3 className="h4 mb-3">Pro Benefits Include:</h3>
                            <ul className="list-unstyled mb-4">
                                {[
                                    'Social Media Manager: Get a dedicated manager to create 2-3 vertical videos per day that will make your restaurant go viral.',
                                    'eLearning Platform: Exclusive access to a professional training platform for your waiters, improving service quality and operational efficiency.',
                                    'Software Recommendations: Get expert advice on the best restaurant management software to streamline operations and maximize profit.',
                                    "Monthly Dinner: Every month, meet the other 11 entrepreneurs in person at a rotating dinner hosted at one of the member's restaurants – a priceless networking opportunity.",
                                ].map((benefit, index) => (
                                    <li
                                        key={index}
                                        className="d-flex align-items-start mb-3"
                                    >
                                        <svg
                                            className="text-success me-2 mt-1"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <span>{benefit}</span>
                                    </li>
                                ))}
                            </ul>
                            <div
                                className={`${styles.priceBanner} bg-success text-white p-3 rounded-3 mb-4`}
                            >
                                <h3 className="h5 mb-0">
                                    Price: CHF 1500/month
                                </h3>
                                <p className="mb-0">
                                    The benefits will pay for themselves many
                                    times over.
                                </p>
                            </div>
                            <div className={styles.ctaWrapper}>
                                <button className="btn btn-success btn-lg w-100">
                                    Apply for AX Gastro Pro
                                </button>
                                <small className="d-block mt-2 text-muted text-center">
                                    Don't wait! Applications for AX Gastro Pro
                                    membership are only open until the 18th and
                                    spaces fill fast. This is your chance to
                                    secure one of the 12 coveted spots – apply
                                    now!
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AXLandingCard
