import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Home from './pages/Home'
import Community from './pages/Community'
import Careers from './pages/Careers'
import Contact from './pages/Contact'
import Investors from './pages/Investors'
import PrivacyPolicy from './pages/PrivacyPolicy'
import AXGastro from './pages/AX-Gastro/AX-Gastro'
import AXGastroLanding from './pages/AX-Gastro/AX-GastroLanding'

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/community" element={<Community />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/investors" element={<Investors />} />
                <Route path="/ax-gastro" element={<AXGastro />} />
                <Route
                    path="/ax-gastro-landing"
                    element={<AXGastroLanding />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
        </Router>
    )
}

export default App
