import React from 'react'
import Fade from 'react-reveal/Fade'

const partners = [
    {
        src: './assets/vector/partner-lvdere.svg',
        alt: 'Lvdere GmbH',
        link: 'https://lvdere.com',
    },
    {
        src: './assets/vector/partner-odoo.svg',
        alt: 'Odoo SA',
        link: 'https://odoo.com',
    },
    {
        src: './assets/vector/partner-pusaq8.svg',
        alt: 'Pusaq8 Ltd',
        link: 'https://pusaq8.com',
    },
    {
        src: './assets/vector/partner-lippcomm.svg',
        alt: 'LippComm',
        link: 'https://lippcomm.ch',
    },
]

const AXLandingPartners = () => {
    return (
        <div id="social-links" className="py-5">
            <Fade bottom cascade>
                <div className="container-fluid social">
                    {partners.map((partner, idx) => (
                        <a
                            key={idx}
                            href={partner.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="item"
                            aria-label={partner.alt}
                        >
                            <img
                                src={partner.src}
                                alt={partner.alt}
                                loading="lazy"
                            />
                        </a>
                    ))}
                </div>
            </Fade>
        </div>
    )
}

export default AXLandingPartners
