import React from 'react'
import Fade from 'react-reveal/Fade'
import Footer from 'src/components/Footer'
import { useTranslation } from 'react-i18next'

/** This is the Homepage of our Website, we are using the i18n for translation */

function AXGastroLanding() {
    const { t } = useTranslation()

    // Function to handle conversion reporting
    const gtagReportConversion = (url) => {
        const callback = function () {
            if (typeof url !== 'undefined') {
                window.location = url
            }
        }
        if (typeof gtag === 'function') {
            window.gtag('event', 'conversion', {
                send_to: 'AW-11096643935/ttwjCJGM_IsaEN-ypasp',
                value: 5.0,
                currency: 'CHF',
                event_callback: callback,
            })
        } else {
            console.warn('gtag function is not defined')
        }
        return false // Prevent default link behavior
    }

    return (
        <div>
            <div id="hero">
                <div className="container-fluid">
                    <img
                        src="./assets/vector/bg-hero-line.svg"
                        className="line"
                        alt="line"
                    />
                    <div className="row">
                        <div className="col-md-6 order-2 order-md-1 left d-flex justify-content-center align-items-start flex-column">
                            <Fade cascade>
                                <div>
                                    <h1>
                                        Willkommen in der AX Gastro Community
                                    </h1>
                                    <div>
                                        <a
                                            href="https://qu3y1cq8u9e.typeform.com/to/toR5Crs7"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={(e) => {
                                                e.preventDefault() // Prevent default link action
                                                gtagReportConversion(
                                                    'https://qu3y1cq8u9e.typeform.com/to/toR5Crs7'
                                                )
                                            }}
                                        >
                                            <button className="btn mt-4">
                                                Sichern Sie sich Ihr Geschenk
                                                jetzt – Nur für kurze Zeit! 🎁
                                            </button>
                                        </a>
                                    </div>
                                    <h4 className="mt-4">
                                        Automatisieren Sie Ihr Restaurant und
                                        begeistern Sie Ihre Kunden wie nie
                                        zuvor.
                                    </h4>
                                    <p>
                                        <strong>
                                            Treten Sie unserer exklusiven
                                            Community bei
                                        </strong>{' '}
                                        und bringen Sie Ihr Gastronomiegeschäft
                                        auf das nächste Level!
                                    </p>
                                    <ul>
                                        <li>
                                            <strong>
                                                📦 Erhalten Sie das KI-Handbuch
                                            </strong>
                                        </li>
                                        <li>
                                            <strong>
                                                📶 Google Review NFC
                                            </strong>
                                        </li>
                                        <li>
                                            <strong>
                                                📊 Wöchentliche
                                                Gastro-Marketing-Updates
                                            </strong>
                                        </li>
                                        <li>
                                            <strong>
                                                🚀 Nur noch 10 NFC-Codes
                                                verfügbar
                                            </strong>
                                        </li>
                                        <li>
                                            <strong>
                                                🔄 Automatisieren Sie Ihr
                                                Marketing
                                            </strong>
                                        </li>
                                    </ul>
                                </div>
                            </Fade>
                        </div>
                        <div className="col-md-6 order-1 order-md-2 right">
                            <img
                                src="./assets/vector/bg-hero-dots.svg"
                                className="dots"
                                alt="dots"
                            />
                            <Fade>
                                <img
                                    src="./assets/img/lvdere-geschenk.png"
                                    className="right-img"
                                    alt="Geschenk"
                                />
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
            <Footer
                communitylink="https://chat.whatsapp.com/CI8ox7liVhWH6dASGPIaWH"
                subtitle={t('footerhome.subtitle')}
                title={t('footerhome.title')}
                description={t('footerhome.description')}
                cta={t('footerhome.cta')}
                swissfooter={t('footerhome.swissfooter')}
                swissquality={t('footerhome.swissquality')}
                swissinnovation={t('footerhome.swissinnovation')}
                swissprecision={t('footerhome.swissprecision')}
                footertext={t('footerhome.footertext')}
            />
        </div>
    )
}

export default AXGastroLanding
