import React from 'react'
import styles from '../../styles/AX-Gastro/AX-Gastro.css'

const AXLandingStories = () => {
    return (
        <section className={`py-5 ${styles.successStoriesSection}`}>
            <div className="container">
                <div className="text-center mb-5">
                    <h2 className="display-5 fw-bold">
                        Real Success Stories – Become the Next Leader
                    </h2>
                    <p className="lead">
                        Hear how our current members transformed their
                        businesses with AX Gastro's guidance. You could be the
                        next success story – but only if you act now!
                    </p>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6">
                        <div
                            className={`${styles.testimonial} bg-light p-4 rounded-4 shadow-sm`}
                        >
                            <div className="d-flex align-items-center mb-3">
                                <img
                                    src="./assets/img/lvdere-Samuele-Dassati.png"
                                    alt="Restaurant owner"
                                    className="rounded-circle me-3"
                                />
                                <div>
                                    <h5 className="mb-0">Sarah Thompson</h5>
                                    <p className="text-muted mb-0">
                                        Owner, The Rustic Table
                                    </p>
                                </div>
                            </div>
                            <p className="mb-0">
                                "Joining the AX Gastro Community was a
                                game-changer for my restaurant. The marketing
                                strategies and automation tools I learned about
                                helped me increase my revenue by 40% in just
                                three months. The networking opportunities are
                                priceless!"
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AXLandingStories
