import React, { useState, useRef, useEffect } from 'react'

const ImageSlider = ({ image1, image2 }) => {
    const [sliderPosition, setSliderPosition] = useState(50)
    const isDragging = useRef(false)
    const sliderRef = useRef(null)

    useEffect(() => {
        const handleMouseMove = (event) => {
            if (!isDragging.current || !sliderRef.current) return

            const slider = sliderRef.current
            const rect = slider.getBoundingClientRect()
            let position = ((event.clientX - rect.left) / rect.width) * 100
            position = Math.max(0, Math.min(100, position))
            setSliderPosition(position)
        }

        const handleMouseUp = () => {
            isDragging.current = false
        }

        const handleTouchMove = (event) => {
            if (!isDragging.current || !sliderRef.current) return
            const slider = sliderRef.current
            const rect = slider.getBoundingClientRect()
            const touch = event.touches[0]
            let position = ((touch.clientX - rect.left) / rect.width) * 100
            position = Math.max(0, Math.min(100, position))
            setSliderPosition(position)
        }

        const handleTouchEnd = () => {
            isDragging.current = false
        }

        window.addEventListener('mousemove', handleMouseMove)
        window.addEventListener('mouseup', handleMouseUp)
        window.addEventListener('touchmove', handleTouchMove)
        window.addEventListener('touchend', handleTouchEnd)

        return () => {
            window.removeEventListener('mousemove', handleMouseMove)
            window.removeEventListener('mouseup', handleMouseUp)
            window.removeEventListener('touchmove', handleTouchMove)
            window.removeEventListener('touchend', handleTouchEnd)
        }
    }, [])

    const handleMouseDown = () => {
        isDragging.current = true
    }

    const handleTouchStart = () => {
        isDragging.current = true
    }

    return (
        <div className="image-slider" ref={sliderRef}>
            <img
                src={image1}
                alt="First"
                style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}
            />
            <img
                src={image2}
                alt="Second"
                style={{ clipPath: `inset(0 0 0 ${sliderPosition}%)` }}
            />
            {/* The draggable button */}
            <button
                className="slider-handle"
                style={{ left: `${sliderPosition}%` }}
                onMouseDown={handleMouseDown}
                onTouchStart={handleTouchStart}
            />
        </div>
    )
}

export default ImageSlider
