import React from 'react'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import { useTranslation } from 'react-i18next'

import AXLandingSignUp from 'src/components/AX-Landing/AX-LandingSignUp'
import AXLandingTools from 'src/components/AX-Landing/AX-LandingTools'
import AXLandingGrowth from 'src/components/AX-Landing/AX-LandingGrowth'
import AXLandingStories from 'src/components/AX-Landing/AX-LandingStories'
import AXLandingCard from 'src/components/AX-Landing/AX-LandingCard'
import AXLandingAccordion from 'src/components/AX-Landing/AX-LandingAccordion'
import AXLandingVideo from 'src/components/AX-Landing/AX-LandingVideoSection'
import AXLandingPartners from 'src/components/AX-Landing/AX-LandingPartners'

/**This is the Homepage of our Website, we are using the i18n for translation */

function AXGastro() {
    const { t } = useTranslation()

    return (
        <div>
            <Navbar
                menu1={t('navbar.menu1') || 'Communities'}
                menu2={t('navbar.menu2') || 'Careers'}
                menu3={t('navbar.menu3') || '4 Investors'}
                menu4={t('navbar.menu4') || 'Contact Us'}
            />
            <AXLandingSignUp community="axgastro" />
            <AXLandingPartners />
            <AXLandingTools community="axgastro" />
            <AXLandingGrowth />
            <AXLandingStories />
            <AXLandingAccordion />
            <AXLandingCard />
            <AXLandingVideo />
            <Footer
                communitylink="https://chat.whatsapp.com/CI8ox7liVhWH6dASGPIaWH"
                subtitle={t('footerhome.subtitle')}
                title={t('footerhome.title')}
                description={t('footerhome.description')}
                cta={t('footerhome.cta')}
                swissfooter={t('footerhome.swissfooter')}
                swissquality={t('footerhome.swissquality')}
                swissinnovation={t('footerhome.swissinnovation')}
                swissprecision={t('footerhome.swissprecision')}
                footertext={t('footerhome.footertext')}
            />
        </div>
    )
}

export default AXGastro
