import React, { useEffect, useState } from 'react'
import styles from '../../styles/AX-Gastro/AX-Gastro.css'
import { useTranslation } from 'react-i18next'

const LandingTools = ({ community }) => {
    const { t } = useTranslation() // Use the i18n hook
    const [timeLeft, setTimeLeft] = useState(24 * 60 * 60) // 24 hours in seconds

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0))
        }, 1000)
        return () => clearInterval(timer)
    }, [])

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600)
        const minutes = Math.floor((seconds % 3600) / 60)
        const secs = seconds % 60
        return `${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
    }

    const toolsList = [
        t(`${community}landing.tools.PriorityAccesstoTrends`),
        t(`${community}landing.tools.ExclusiveAutomationTips`),
        t(`${community}landing.tools.InsiderIndustryUpdates`),
    ]

    return (
        <section className={`py-5 ${styles.trendsSection}`}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 order-lg-2 mb-5 mb-lg-0">
                        <div className="d-flex flex-column gap-4">
                            <div className="d-flex align-items-center">
                                <span className={styles.emoji}>🔥</span>
                                <span className="text-danger fw-semibold ms-2">
                                    {t(`${community}landing.tools.trending`)}
                                </span>
                            </div>
                            <h2 className="display-5 fw-bold mb-3">
                                {t(`${community}landing.tools.title`)}
                            </h2>
                            <p className="lead mb-4">
                                {t(`${community}landing.tools.description`)}
                            </p>
                            <ul className="list-unstyled mb-4">
                                {toolsList.map((item, index) => (
                                    <li
                                        key={index}
                                        className="d-flex align-items-center mb-2"
                                    >
                                        <svg
                                            className="text-danger me-2"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                            <div
                                className={`${styles.urgencyBanner} bg-danger text-white p-3 rounded-3`}
                            >
                                <h3 className="h5 mb-2">
                                    🚨 Limited Time Offer!
                                </h3>
                                <p className="mb-2">
                                    Be the first in your area to implement these
                                    game-changing strategies. Join now before
                                    your competitors do!
                                </p>
                                <div className={styles.countdownTimer}>
                                    <span className="fw-bold">Time Left: </span>
                                    <span className={styles.timerDigits}>
                                        {formatTime(timeLeft)}
                                    </span>
                                </div>
                            </div>
                            <div className={styles.ctaWrapper}>
                                <button className="btn btn-success btn-lg">
                                    Get Exclusive Access Now
                                </button>
                                <small className="d-block mt-2 text-muted">
                                    Only 50 spots left for priority access!
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 order-lg-1">
                        <div className={`${styles.imageWrapper}`}>
                            <div
                                className={`${styles.trendImage} rounded-4 bg-success`}
                            >
                                <img
                                    src="../assets/img/Illustration.png"
                                    alt="Cutting-edge restaurant technology"
                                    className="img-fluid"
                                />
                            </div>
                            <div
                                className={`${styles.trendCard} bg-white rounded-4 p-3 shadow`}
                            >
                                <div className={`${styles.trendIcon} mb-2`}>
                                    <svg
                                        width="40"
                                        height="40"
                                        viewBox="0 0 40 40"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle
                                            cx="20"
                                            cy="20"
                                            r="20"
                                            fill="#FFC107"
                                        />
                                        <path
                                            d="M20 10V30M10 20H30"
                                            stroke="white"
                                            strokeWidth="4"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                                <div className="text-dark fw-bold fs-4">
                                    New Trend Alert
                                </div>
                                <div className="text-muted">
                                    Be the First to Know
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LandingTools
