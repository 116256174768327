import React from 'react'

function AXLandingVideo({
    card1number,
    card2number,
    card3number,
    card4number,
}) {
    const stats = [
        { count: '20+', label: card1number },
        { count: '10+', label: card2number },
        { count: '30+', label: card3number },
        { count: '20+', label: card4number },
    ]

    return (
        <div id="services" className="video-section">
            <div className="container-fluid">
                <div className="video-wrapper mb-150px">
                    <iframe
                        className="video-wrapper-img"
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/DMPZ8saKAzY"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    <div className="video-wrapper-cards">
                        {stats.map((stat, index) => (
                            <div
                                className={`video-wrapper-card text-center ${
                                    index !== stats.length - 1
                                        ? 'with-border'
                                        : ''
                                }`}
                                key={index}
                            >
                                <p className="fs-36px weight-8">{stat.count}</p>
                                <p className="fs-20px weight-5">{stat.label}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AXLandingVideo
