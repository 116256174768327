import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from '../../styles/AX-Gastro/AX-Gastro.css'

const AXLandingSignUp = ({ community }) => {
    // Destructure the `community` prop
    const { t } = useTranslation() // Use the i18n hook
    const [submitted, setSubmitted] = useState(false)
    return (
        <section className={styles.section}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 mb-5 mb-lg-0">
                        <div className="d-flex flex-column gap-4">
                            <div className="d-flex align-items-center">
                                <span className={styles.emoji}>🚀</span>
                                <span className="text-primary fw-semibold ms-2">
                                    {t(
                                        `${community}landing.signup.limitedTimeOffer`
                                    )}
                                </span>
                            </div>
                            <h1 className="display-4 fw-bold mb-3">
                                {t(`${community}landing.signup.title`)}
                            </h1>
                            <p className="lead mb-4">
                                {t(`${community}landing.signup.description`)}
                            </p>
                            <ul className="list-unstyled mb-4">
                                {[
                                    // Make sure the keys are dynamically loaded with the `community`
                                    t(
                                        `${community}landing.signup.expertInsights`
                                    ),
                                    t(
                                        `${community}landing.signup.networkingOpportunities`
                                    ),
                                    t(
                                        `${community}landing.signup.exclusiveResources`
                                    ),
                                ].map((item, index) => (
                                    <li
                                        key={index}
                                        className="d-flex align-items-center mb-2"
                                    >
                                        <svg
                                            className="text-success me-2"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className={styles.imageWrapper}>
                            <div className={`${styles.mainImage} rounded-4`}>
                                <img
                                    src="./assets/img/hero.png"
                                    alt={t(
                                        `${community}landing.signup.restaurantOwnerAlt`
                                    )}
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AXLandingSignUp
